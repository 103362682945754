var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-5"},[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"12","md":"10"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"30","color":"primary"}},[_vm._v("mdi-alert")]),_c('h2',[_vm._v("Reportes de emergencia")])],1),_c('v-col',{staticClass:"d-flex justify-center justify-md-end align-center",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"info","rounded":""},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v(" Recargar ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"search",staticClass:"mb-4",attrs:{"name":"search","prepend-inner-icon":"mdi-magnify","label":"Buscar por folio de reporte","hide-details":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"label":"Filtrar por estatus","items":_vm.statuses,"hide-details":"","outlined":""},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"label":"Cancelados","items":_vm.cancelled,"hide-details":"","outlined":""},model:{value:(_vm.cancelSelected),callback:function ($$v) {_vm.cancelSelected=$$v},expression:"cancelSelected"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.dataFiltered,"items-per-page":20,"custom-filter":_vm.filterOnlyCapsText,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.user.name + " " + item.user.lastname))])]}},{key:"item.user.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.user.phone))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":_vm.getStatusText(item)[1],"small":"","outlined":!!item.deleted_at,"dark":""}},[(
              (item.status == 'Recibido' || item.status == 'No atendido') &&
              item.deleted_at == null
            )?_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getStatusText(item)[0])+" ")],1)],1)]}},{key:"item.police",fn:function(ref){
            var item = ref.item;
return [_c('div',[(!!item.police)?_c('v-chip',{attrs:{"small":"","color":"black","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.police.name + " " + item.police.lastname)+" ")]):_c('v-chip',{attrs:{"label":"","color":"blue-grey","outlined":"","small":""}},[_vm._v(" Sin oficial asignado ")])],1)]}},{key:"item.admin",fn:function(ref){
            var item = ref.item;
return [_c('div',[(!!item.admin)?_c('v-chip',{attrs:{"small":"","color":"black","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.admin.name + " " + item.admin.lastname)+" ")]):_c('v-chip',{attrs:{"label":"","color":"blue-grey","outlined":"","small":""}},[_vm._v(" Sin asignar ")])],1)]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.deleted_at == null)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.gotDetails(item)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-eye ")]),_vm._v(" Ver detalles ")],1):_vm._e()]}}])}),_c('audio',{staticStyle:{"display":"none"},attrs:{"id":"notification","preload":"","src":"/notification.mp3"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }